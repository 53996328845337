




























































































































@import './src/styles/variables';

.signIn {
  &__logo {
    vertical-align: middle;
    text-align: center;
    max-width: 100%;
    margin-bottom: 24px;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: 264px;
      margin-bottom: 32px;
    }
  }
}

.limited-content {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.billboard {
  &__wrapper {
    position: fixed;
    right: 0;
    min-height: auto;
    height: 100vh;
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $body-bg;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__message {
    position: absolute;
    right: 5%;
    left: 5%;
    bottom: 15%;
    padding-top: 0;
    margin: auto;
    text-align: center;
    max-width: 480px;
    color: $body-bg;
    font-weight: 500;
    font-size: 120%;
  }
}
